<template>
    <div v-if="show" class="relative z-[80]">
        <div
            class="pointer-events-none fixed inset-0 z-[1000] overscroll-none bg-black bg-opacity-70 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-[1001] overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 sm:p-0">
                <div
                    ref="ModalWindow"
                    class="relative w-full transform overflow-hidden rounded-md transition-all sm:my-8 sm:w-full lg:rounded-[10px]"
                    :class="[
                        classes,
                        {
                            'sm:max-w-sm': size === 'sm',
                            'sm:max-w-md': size === 'md',
                            'sm:max-w-lg': size === 'lg',
                            'sm:max-w-xl': size === 'xl',
                            'sm:max-w-2xl': size === 'xxl',
                        },
                    ]"
                >
                    <div class="relative text-center">
                        <slot name="header"></slot>
                        <div
                            v-if="!hideClose"
                            class="absolute right-3 top-3 h-[30px] w-[30px] cursor-pointer"
                            @click="emit('close')"
                        >
                            <img
                                :src="`${staticUrl}/assets/icons/close.svg`"
                                alt="close"
                            />
                        </div>
                    </div>
                    <slot></slot>
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onClickOutside } from '@vueuse/core'
const staticUrl = useRuntimeConfig().public.staticUrl
const { show, sticky } = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    hideClose: {
        type: Boolean,
        default: false,
    },
    sticky: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'lg',
    },
    classes: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['close'])
const ModalWindow = ref(null)

onClickOutside(ModalWindow, () => {
    if (!sticky) {
        emit('close')
    }
})
</script>
